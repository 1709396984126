<template>
  <div>
    <el-form ref="formRef" :model="form" label-width="120px" style="width: 800px">
      <el-form-item label="帖子内容">
        <el-input v-model="content.content" type="textarea" rows="5"></el-input>
      </el-form-item>
      <el-form-item label="帖子图片">
        <el-upload
          action="https://api.yjqn.club/common/upload/upload"
          list-type="picture-card"
          :limit="9"
          :file-list="pic_flag"
          :on-preview="handlePictureCardPreview"
          :on-success="uploadSuccess"
          :on-remove="uploadRemove"
        >
          <el-icon><plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="帖子话题">
        <el-radio-group v-model="content.topic_id" >
          <el-radio :label="item.id" v-for="(item, index) in classfiy_list" :key="index" @change="changeRadio(item)">
            <div>{{item.alias}}</div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <div style="margin-left: 50px">
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="back">返回</el-button>
      </div>
    </el-form>
    <el-dialog v-model="dialogVisible">
      <img width="300" :src="dialogImageUrl" alt="" style="margin: 0 auto;display: block"/>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "SocialDetail",
  data() {
    return {
      content: {
        content: '',
        topic_id: '',
        topic_title: '',
      },
      files: [],
      dialogImageUrl: '',
      dialogVisible: false,
      pic_flag: [],
      classfiy_list: [],
      article_id: ''
    }
  },
  computed: {
    ...mapState(['globalData'])
  },
  created() {
    console.log(this.$route.query)

    this.$api.articleTalkTopic.list().then(res => {
      this.classfiy_list = res.data
    })
    if(this.$route.query.id) {
      this.article_id = this.$route.query.id
      this.$api.article.detail(this.article_id).then(res => {
        console.log(res);
        const data = res.data;
        this.content = {
          content: data.sub.content,
          topic_id: data.sub.topic_id,
          topic_title: data.sub.topic_title,
        }
        this.files = data.file;
        let pic_flag = []
        this.files.forEach((item, index) => {
          pic_flag.push({
            name: index + '.png',
            url: this.globalData.cos_domain + item.url
          })
        })
        this.pic_flag = pic_flag;
      })
    }
  },
  methods: {
    onSubmit(e) {
      let params = {
        content: this.content,
        files: this.files,
      }
      if (this.article_id) {
        params.article_id = this.article_id
      }
      if(this.article_id) {
        this.$api.article.editTalk(params).then(res => {
          this.$router.back();
        })
      } else {
        this.$api.article.talk(params).then(res => {
          this.$router.back();
        })
      }
    },
    back() {
      this.$router.back()
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    uploadSuccess(e) {
      console.log(e);
      this.files.push({
        thumbnail: '',
        url: e.data.file_url,
      })
    },
    uploadRemove(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.files = [];
      fileList.forEach(item => {
        this.files.push({
          thumbnail: '',
          url: item.response.data.file_url,
        })
      })
    },
    changeRadio(data) {
      this.content.topic_title = data.title;
    }
  }
}
</script>

<style scoped>

</style>
